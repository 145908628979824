import { teamEdit } from 'constants/admin/teams';

const initialState = {
  teamId: null,
  logo: '#',
  initialValues: {}
};

const normalizeInitialData = (payload) => {
  const {
    ...rest
  } = payload;

  return {
    initialValues: {
      ...rest
    }
  };
};

const editTeamReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case teamEdit.SET_TEAM_ID:
      return {
        ...state,
        teamId: payload
      };
    case teamEdit.AVATAR_SUBMIT_OK:
      return {
        ...state,
        logo: payload.logo
      };
    case teamEdit.DARK_THEME_AVATAR_SUBMIT_OK:
      return {
        ...state,
        logoDarkTheme: payload.logo_dark_theme
      };
    case teamEdit.FETCH_FORM_DATA_OK:
      return {
        ...state,
        ...normalizeInitialData(payload)
      };
    default:
      return state;
  }
};

export default editTeamReducer;
