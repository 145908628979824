import { createReducer } from 'redux-act';
import * as actions from 'actions/core/sign';

const initialState = {
  signed_in: false
};

export default createReducer({

  [actions.isSignedUser]: (state, data) => ({ ...state, signed_in: data }),
  [actions.callIsSignedUser]: state => ({ ...state })

}, initialState);
