import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


function addElement(children, domId) {
  const container = document.getElementById(domId);
  if (document.getElementById(domId) != null) {
    ReactDOM.render(children, container);
  }
  return '';
}

export default class Injector extends Component {
  static propTypes = {
    domId: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
  };

  componentDidMount() {
    const { children, domId } = this.props;
    addElement(children, domId);
  }

  render() {
    return null;
  }
}
