import * as users from 'constants/admin/users';

/**
 * @namespace
 * @property {object} formState
 * @property {boolean} formState.isErred - show if form has error
 * @notification {string} formState.notification - notification for alerts
 */
const initialState = {
  formState: {
    isErrored: false,
    notification: null
  }
};

const normalizeInitialData = data => ({
  countries: data.countries.sort((a, b) => (a.value >= b.value ? 1 : -1)),
  roles: data.roles,
  routes: data.routes,
  user: {
    ...data.user,
    country: data.user.country && typeof data.user.country === 'string' && data.user.country.toLowerCase(),
    gender: data.user && data.user.gender || 'other'
  }
});

const adminUsersEditReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case users.OK_FETCH_DATA_ADMIN_USERS_EDIT:
      return {
        ...state,
        ...normalizeInitialData(payload),
        formSate: {
          ...state.formState,
          isErrored: false
        }
      };
    case users.OK_SUBMIT_FORM_ADMIN_USERS_EDIT:
      return {
        ...state,
        formState: {
          ...state.formState,
          isErrored: false
        }
      };
    case users.FAIL_SUBMIT_FORM_ADMIN_USERS_EDIT:
      return {
        ...state,
        formState: {
          ...state.formState,
          isErrored: true
        }
      };
    case users.OK_SUBMIT_AVATAR_ADMIN_USERS_EDIT:
      return {
        ...state,
        routes: {
          ...state.routes,
          prevAvatarUrl: payload.logo_270
        },
        formState: {
          ...state.formState,
          isErrored: false
        }
      };

    case users.FAIL_SUBMIT_AVATAR_ADMIN_USERS_EDIT:
      return {
        ...state,
        formState: {
          ...state.formState,
          isErrored: true
        }
      };
    case users.SHOW_ALERT_ADMIN_USERS_EDIT:
      return {
        ...state,
        formState: {
          ...state.formState,
          notification: payload
        }
      };
    case users.CLOSE_ALERT_ADMIN_USERS_EDIT:
      return {
        ...state,
        formState: {
          ...state.formState,
          notification: null
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default adminUsersEditReducer;
