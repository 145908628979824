import 'whatwg-fetch';
import ReactOnRails from 'react-on-rails';
import axios from 'axios/index';

function createFetch(fetch, { locale }) {
  const defaults = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return async (url, options) => {
    const csrfToken = ReactOnRails.authenticityToken();
    return axios(`/${locale}${url}`, {
      ...defaults,
      ...options,
      headers: {
        ...defaults.headers,
        'X-CSRF-Token': csrfToken,
        ...(options && options.headers)
      },
      data: options.data || {}
    });
  };
}

export default createFetch;
