import moment from 'moment-timezone';

function plural(word, num) {
  const forms = word.split('_');
  return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]);
}
function relativeTimeWithPlural(number, withoutSuffix, key) {
  const format = {
    ss: withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
    mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
    hh: 'час_часа_часов',
    dd: 'день_дня_дней',
    MM: 'месяц_месяца_месяцев',
    yy: 'год_года_лет'
  };
  if (key === 'm') {
    return withoutSuffix ? 'минута' : 'минуту';
  }

  return `${number} ${plural(format[key], +number)}`;
}
const monthsParse = [/^Янв/i, /^Фев/i, /^Мар/i, /^Апр/i, /^Ма[йя]/i, /^Июн/i, /^Июл/i, /^Авг/i, /^Сен/i, /^Окт/i, /^Ноя/i, /^Дек/i];

export default moment.defineLocale('ru', {
  months: {
    format: 'Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря'.split('_'),
    standalone: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_')
  },
  monthsShort: {
    // по CLDR именно "июл." и "июн.", но какой смысл менять букву на точку ?
    format: 'Янв_Фев_Мар_Апр_Мая_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
    standalone: 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_')
  },
  weekdays: {
    standalone: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
    format: 'воскресенье_понедельник_вторник_среду_четверг_пятницу_субботу'.split('_'),
    isFormat: /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?\] ?dddd/
  },
  weekdaysShort: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
  weekdaysMin: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
  monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,

  // полные названия с падежами, по три буквы, для некоторых, по 4 буквы, сокращения с точкой и без точки
  monthsRegex: /^(Январ[ья]|Янв\.?|Феврал[ья]|Февр?\.?|Марта?|Мар\.?|Апрел[ья]|Апр\.?|Ма[йя]|Июн[ья]|Июн\.?|Июл[ья]|Июл\.?|Августа?|Авг\.?|Сентябр[ья]|Сент?\.?|Октябр[ья]|Окт\.?|Ноябр[ья]|Нояб?\.?|Декабр[ья]|Дек\.?)/i,

  // копия предыдущего
  monthsShortRegex: /^(Январ[ья]|Янв\.?|Феврал[ья]|Февр?\.?|Марта?|Мар\.?|Апрел[ья]|Апр\.?|Ма[йя]|Июн[ья]|Июн\.?|Июл[ья]|Июл\.?|Августа?|Авг\.?|Сентябр[ья]|Сент?\.?|Октябр[ья]|Окт\.?|Ноябр[ья]|Нояб?\.?|Декабр[ья]|Дек\.?)/i,

  // полные названия с падежами
  monthsStrictRegex: /^(Январ[яь]|Феврал[яь]|Марта?|Апрел[яь]|Ма[яй]|Июн[яь]|Июл[яь]|Августа?|Сентябр[яь]|Октябр[яь]|Ноябр[яь]|Декабр[яь])/i,

  // Выражение, которое соотвествует только сокращённым формам
  monthsShortStrictRegex: /^(Янв\.|Февр?\.|Мар[т.]|Апр\.|Ма[яй]|Июн[ья.]|Июл[ья.]|Авг\.|Сент?\.|окт\.|Нояб?\.|Дек\.)/i,
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY г.',
    LLL: 'D MMMM YYYY г., H:mm',
    LLLL: 'dddd, D MMMM YYYY г., H:mm'
  },
  calendar: {
    sameDay: '[Сегодня, в] LT',
    nextDay: '[Завтра, в] LT',
    lastDay: '[Вчера, в] LT',
    nextWeek(now) {
      if (now.week() !== this.week()) {
        switch (this.day()) {
          case 0:
            return '[В следующее] dddd, [в] LT';
          case 1:
          case 2:
          case 4:
            return '[В следующий] dddd, [в] LT';
          case 3:
          case 5:
          case 6:
            return '[В следующую] dddd, [в] LT';
        }
      } else if (this.day() === 2) {
        return '[Во] dddd, [в] LT';
      } else {
        return '[В] dddd, [в] LT';
      }
    },
    lastWeek(now) {
      if (now.week() !== this.week()) {
        switch (this.day()) {
          case 0:
            return '[В прошлое] dddd, [в] LT';
          case 1:
          case 2:
          case 4:
            return '[В прошлый] dddd, [в] LT';
          case 3:
          case 5:
          case 6:
            return '[В прошлую] dddd, [в] LT';
        }
      } else if (this.day() === 2) {
        return '[Во] dddd, [в] LT';
      } else {
        return '[В] dddd, [в] LT';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    ss: relativeTimeWithPlural,
    m: relativeTimeWithPlural,
    mm: relativeTimeWithPlural,
    h: 'час',
    hh: relativeTimeWithPlural,
    d: 'день',
    dd: relativeTimeWithPlural,
    M: 'месяц',
    MM: relativeTimeWithPlural,
    y: 'год',
    yy: relativeTimeWithPlural
  },
  meridiemParse: /ночи|утра|дня|вечера/i,
  isPM(input) {
    return /^(дня|вечера)$/.test(input);
  },
  meridiem(hour, minute, isLower) {
    if (hour < 4) {
      return 'ночи';
    } else if (hour < 12) {
      return 'утра';
    } else if (hour < 17) {
      return 'дня';
    }
    return 'вечера';
  },
  dayOfMonthOrdinalParse: /\d{1,2}-(й|го|я)/,
  ordinal(number, period) {
    switch (period) {
      case 'M':
      case 'd':
      case 'DDD':
        return `${number}-й`;
      case 'D':
        return `${number}-го`;
      case 'w':
      case 'W':
        return `${number}-я`;
      default:
        return number;
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
});
