import { memberships } from 'constants/admin/teams';
import { SORTING_ASC } from 'constants/admin';


const initialModals = {
  proposalNewMember: null,
  proposalDeleteMember: null,
  errorMessage: '',
  exchange: {
    showExchangeModal: false,
    propose: null,
    player: null,
    isChangingRoles: false
  }
};

const initialState = {
  sorting: {
    role: SORTING_ASC
  },
  members: [],
  maxPrimaryMembers: 0,
  isLoading: false,
  ...initialModals
};

const normalizeError = (data) => {
  if (Array.isArray(data) && data.length) return data.join(' ');
  if (typeof data === 'string') return data;
  return 'Something wrong !';
};

const membershipsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case memberships.SET_TEAM_ID:
      return {
        ...state,
        teamId: payload
      };
    case memberships.PROPOSAL_NEW: {
      return {
        ...state,
        proposalNewMember: payload
      };
    }
    case memberships.PROPOSAL_DELETE: {
      return {
        ...state,
        proposalDeleteMember: payload
      };
    }
    case memberships.SHOW_ERROR_MODAL: {
      return {
        ...state,
        errorMessage: normalizeError(payload)
      };
    }
    case memberships.CLOSE_MODALS: {
      return {
        ...state,
        ...initialModals
      };
    }
    case memberships.UPDATE_SORTING:
      return {
        ...state,
        sorting: payload
      };
    case memberships.RESET_SORTING:
      return {
        ...state,
        sorting: initialState.sorting
      };
    case memberships.FETCH_MEMBERS_START:
      return {
        ...state,
        isLoading: true
      };
    case memberships.UPDATE_MEMBERS:
      return {
        ...state,
        members: payload
      };
    case memberships.FETCH_MEMBERS_OK:
      return {
        ...state,
        members: payload.result,
        maxPrimaryMembers: payload.max_primary_members,
        isLoading: false
      };
    case memberships.SHOW_EXCHANGE_MODAL:
      return {
        ...state,
        exchange: {
          showExchangeModal: true,
          propose: payload.propose,
          player: payload.player
        }
      };
    case memberships.FETCH_ROLES_CHANGE_START:
      return {
        ...state,
        ...initialModals,
        exchange: {
          ...initialModals.exchange,
          isChangingRoles: true
        }
      };
    case memberships.FETCH_ROLES_CHANGE_OK:
      return {
        ...state,
        members: payload,
        ...initialModals
      };
    case memberships.FETCH_ROLES_CHANGE_FAIL:
      return {
        ...state,
        exchange: {
          ...state.exchange,
          isChangingRoles: false
        }
      };
    case memberships.CLOSE_EXCHANGE_MODAL:
      return {
        ...state,
        exchange: {
          showExchangeModal: false,
          propose: null,
          player: null
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default membershipsReducer;
