// list user disciplines actions

export const listActions = Object.freeze({
  FETCH_DATA_START: 'FETCH_DATA_START/ADMIN_USER_DISCIPLINES',
  FETCH_DATA_OK: 'FETCH_DATA_OK/ADMIN_USER_DISCIPLINES',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL/ADMIN_USER_DISCIPLINES',

  FETCH_COUNTRIES_START: 'FETCH_COUNTRIES_START/ADMIN_USER_DISCIPLINES',
  FETCH_COUNTRIES_OK: 'FETCH_COUNTRIES_OK/ADMIN_USER_DISCIPLINES',
  FETCH_COUNTRIES_FAIL: 'FETCH_COUNTRIES_FAIL/ADMIN_USER_DISCIPLINES',

  FETCH_DISCIPLINES_START: 'FETCH_DISCIPLINES_START/ADMIN_USER_DISCIPLINES',
  FETCH_DISCIPLINES_OK: 'FETCH_DISCIPLINES_OK/ADMIN_USER_DISCIPLINES',
  FETCH_DISCIPLINES_FAIL: 'FETCH_DISCIPLINES_FAIL/ADMIN_USER_DISCIPLINES',

  FETCH_REGIONS_START: 'FETCH_REGIONS_START/ADMIN_USER_DISCIPLINES',
  FETCH_REGIONS_OK: 'FETCH_REGIONS_OK/ADMIN_USER_DISCIPLINES',
  FETCH_REGIONS_FAIL: 'FETCH_REGIONS_FAIL/ADMIN_USER_DISCIPLINES',
  RESET_REGIONS: 'RESET_REGIONS/ADMIN_USER_DISCIPLINES',

  UPDATE_FILTER: 'UPDATE_FILTER/ADMIN_USER_DISCIPLINES',
  RESET_FILTER: 'RESET_FILTER/ADMIN_USER_DISCIPLINES',

  CHANGE_PER_PAGE: 'CHANGE_PER_PAGE/ADMIN_USER_DISCIPLINES',
  CHANGE_PAGE: 'CHANGE_PAGE/ADMIN_USER_DISCIPLINES'
});

// new user discipline actions
export const createActions = Object.freeze({
  REMOVE_LOGO_LINK: 'REMOVE_LOGO_LINK/ADMIN_USER_DISCIPLINES_NEW',
  RESET_REGIONS: 'RESET_REGIONS/ADMIN_USER_DISCIPLINES_NEW',
  SET_AVATAR: 'SET_AVATAR/ADMIN_USER_DISCIPLINES_NEW',
  UPDATE_USER: 'UPDATE_USER/ADMIN_USER_DISCIPLINES_NEW',

  FETCH_INITIAL_DATA_START: 'FETCH_INITIAL_DATA_START/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_INITIAL_DATA_OK: 'FETCH_INITIAL_DATA_OK/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_INITIAL_DATA_FAIL: 'FETCH_INITIAL_DATA_FAIL/ADMIN_USER_DISCIPLINES_NEW',

  FETCH_DISCIPLINES_START: 'START_FETCH_DISCIPLINES/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_DISCIPLINES_OK: 'FETCH_DISCIPLINES_OK/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_DISCIPLINES_FAIL: 'FETCH_DISCIPLINES_FAIL/ADMIN_USER_DISCIPLINES_NEW',

  FETCH_REGIONS_START: 'FETCH_REGIONS_START/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_REGIONS_OK: 'FETCH_REGIONS_OK/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_REGIONS_FAIL: 'FETCH_REGIONS_FAIL/ADMIN_USER_DISCIPLINES_NEW',

  SUBMIT_START: 'SUBMIT_START/ADMIN_USER_DISCIPLINES_NEW',
  SUBMIT_OK: 'SUBMIT_OK/ADMIN_USER_DISCIPLINES_NEW',
  SUBMIT_FAIL: 'SUBMIT_FAIL/ADMIN_USER_DISCIPLINES_NEW',

  SUBMIT_AVATAR_START: 'SUBMIT_AVATAR_START/ADMIN_USER_DISCIPLINES_NEW',
  SUBMIT_AVATAR_OK: 'SUBMIT_AVATAR_OK/ADMIN_USER_DISCIPLINES_NEW',
  SUBMIT_AVATAR_FAIL: 'SUBMIT_AVATAR_FAIL/ADMIN_USER_DISCIPLINES_NEW',

  FETCH_DATA_USER_START: 'FETCH_DATA_USER_START/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_DATA_USER_OK: 'FETCH_DATA_USER_OK/ADMIN_USER_DISCIPLINES_NEW',
  FETCH_DATA_USER_FAIL: 'FETCH_DATA_USER_FAIL/ADMIN_USER_DISCIPLINES_NEW'
});

// edit user discipline actions

export const editActions = Object.freeze({
  UPDATE_USER: 'UPDATE_USER/ADMIN_USER_DISCIPLINES_EDIT',

  FETCH_DATA_START: 'START_FETCH_DATA/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_DATA_OK: 'OK_FETCH_DATA/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_DATA_FAIL: 'FAIL_FETCH_DATA/ADMIN_USER_DISCIPLINES_EDIT',

  FETCH_REGIONS_START: 'START_FETCH_REGIONS/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_REGIONS_OK: 'OK_FETCH_REGIONS/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_REGIONS_FAIL: 'FAIL_FETCH_REGIONS/ADMIN_USER_DISCIPLINES_EDIT',

  FETCH_USERS_START: 'START_FETCH_USERS/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_USERS_OK: 'OK_FETCH_USERS/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_USERS_FAIL: 'FAIL_FETCH_USERS/ADMIN_USER_DISCIPLINES_EDIT',

  SUBMIT_FORM_START: 'START_SUBMIT_FORM/ADMIN_USER_DISCIPLINES_EDIT',
  SUBMIT_FORM_OK: 'OK_SUBMIT_FORM/ADMIN_USER_DISCIPLINES_EDIT',
  SUBMIT_FORM_FAIL: 'FAIL_SUBMIT_FORM/ADMIN_USER_DISCIPLINES_EDIT',

  UPDATE_RATING_START: 'UPDATE_RATING_START/ADMIN_USER_DISCIPLINES_EDIT',
  UPDATE_RATING_OK: 'UPDATE_RATING_OK/ADMIN_USER_DISCIPLINES_EDIT',
  UPDATE_RATING_FAIL: 'UPDATE_RATING_FAIL/ADMIN_USER_DISCIPLINES_EDIT',

  SUBMIT_AVATAR_START: 'SUBMIT_AVATAR_START/ADMIN_USER_DISCIPLINES_EDIT',
  SUBMIT_AVATAR_OK: 'SUBMIT_AVATAR_OK/ADMIN_USER_DISCIPLINES_EDIT',
  SUBMIT_AVATAR_FAIL: 'SUBMIT_AVATAR_FAIL/ADMIN_USER_DISCIPLINES_EDIT',

  NULLIFY_ELO_START: 'NULLIFY_ELO_START/ADMIN_USER_DISCIPLINES_EDIT',
  NULLIFY_ELO_OK: 'NULLIFY_ELO_OK/ADMIN_USER_DISCIPLINES_EDIT',
  NULLIFY_ELO_FAIL: 'NULLIFY_ELO_FAIL/ADMIN_USER_DISCIPLINES_EDIT',

  FETCH_DATA_USER_START: 'FETCH_DATA_USER_START/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_DATA_USER_OK: 'FETCH_DATA_USER_OK/ADMIN_USER_DISCIPLINES_EDIT',
  FETCH_DATA_USER_FAIL: 'FETCH_DATA_USER_FAIL/ADMIN_USER_DISCIPLINES_EDIT',

  CLOSE_ALERT: 'CLOSE_ALERT/ADMIN_USER_DISCIPLINES_EDIT',

  UPDATE_FIELD_START: 'UPDATE_FIELD_START',
  UPDATE_FIELD_OK: 'UPDATE_FIELD_OK',
  UPDATE_FIELD_FAIL: 'UPDATE_FIELD_FAIL'
});

export const filters = Object.freeze({
  BY_ID: 'by_id',
  BY_PROFILE: 'by_profile',
  BY_DISCIPLINE: 'by_discipline',
  BY_REGION: 'by_region',
  BY_GAME_ID: 'by_game_id',
  BY_NICK: 'by_nick',
  BY_TEAM_NAME: 'by_team_name',
  BY_COUNTRY: 'by_country',
  BY_SKYPE: 'by_skype',
  BY_PROVIDER_ID: 'by_provider_id',
  BY_TOURNAMENT_ACCOUNT: 'by_tournament_account',
  BY_PROVIDER_ROLE_ID: 'by_provider_role_id'
});

export const userFieldsArray = [
  'nick',
  'first_name',
  'last_name',
  'provider_role_id',
  'gender',
  'birthday',
  'country',
  'skype',
  'fb_link',
  'vk_link',
  'tw_link',
  'yt_link'
];
