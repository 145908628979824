const formatQueryStringUrl = (url, params = {}) => {
  let fullUrl = `${url}.json?`;

  Object.keys(params).forEach((paramsKey) => {
    if (paramsKey === 'fields') {
      Object.keys(params[paramsKey]).forEach((paramsItemKey) => {
        const paramsItemArr = [...params[paramsKey][paramsItemKey]];
        fullUrl += `fields[${paramsItemKey}]=${paramsItemArr.join(',')}&`;
      });
    } else {
      fullUrl += `${paramsKey}=${params[paramsKey]}&`;
    }
  });
  return fullUrl.slice(0, -1);
};

export default formatQueryStringUrl;
