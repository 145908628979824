import { isFunction } from 'lodash';
import UniversalRouter from 'universal-router';
import routes from '../routes/index';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (isFunction(context.route.load)) {
      return context.route.load().then((route) => {
        if (isFunction(route.default)) {
          return route.default(context, params);
        }
        context.route.children = [route.default];
        return undefined;
      });
    }
    if (isFunction(context.route.action)) {
      return context.route.action(context, params);
    }
    return undefined;
  }
});
