import sortBy from 'lodash/sortBy';

import denormalizeData from 'helpers/json_api_utils/denormalizeData';
import { teamsList, sort } from 'constants/admin/teams';
import { SORTING_DESC } from 'constants/admin';


export const initialState = {
  teams: [],
  sort: { [sort.ID]: SORTING_DESC },
  filter: {},
  pagination: {
    total: 0,
    page: 1,
    pages: 0
  },
  regionsByDiscipline: [],
  isLoadingTeams: false,
  isErrored: false,
  countries: [],
  disciplines: []
};

const adminTeamsList = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case teamsList.FETCH_TEAMS_START:
      return {
        ...state,
        isLoadingTeams: true
      };
    case teamsList.UPDATE_TEAMS:
      return {
        ...state,
        teams: payload.teams,
        isLoadingTeams: payload.isLoadingTeams,
        pagination: {
          ...state.pagination,
          pages: payload.pages,
          total: payload.total
        }
      };
    case teamsList.FETCH_TEAMS_FAIL:
      return {
        ...state,
        isErrored: true
      };
    case teamsList.CHANGE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload
        }
      };
    case teamsList.CHANGE_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          adminPerPage: payload,
          page: 1
        }
      };
    case teamsList.UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload
        }
      };
    case teamsList.RESET_FILTER:
      return {
        ...state,
        filter: {
          ...payload
        }
      };
    case teamsList.RESET_SORTING:
      return {
        ...state,
        sort: {
          ...initialState.sort
        }
      };
    case teamsList.FETCH_REGIONS_OK:
      return {
        ...state,
        regionsByDiscipline: payload
      };
    case teamsList.UPDATE_SORTING:
      return {
        ...state,
        sort: payload
      };
    case teamsList.FETCH_DISCIPLINES_OK:
      return {
        ...state,
        disciplines: sortBy(denormalizeData(payload), 'name')
      };
    default:
      return { ...state };
  }
};

export default adminTeamsList;
