import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'actions/timezones';

import moment from 'moment-timezone';
import classNames from 'classnames';

import { dateTimeFormat } from 'lib/date_time';
import AdminUiIconComponent from 'admin/components/ui/icon';


const TIME_FORMAT = 'time_default';

class AdminTimezoneSelectContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      setCurrentTimezone: PropTypes.func,
      addMyTimezone: PropTypes.func
    }).isRequired,
    locale: PropTypes.string.isRequired,
    currentTime: PropTypes.string.isRequired,
    currentTimezone: PropTypes.shape({
      name: PropTypes.string.isRequired,
      zone: PropTypes.string.isRequired
    }).isRequired,
    timezones: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      tz: PropTypes.string
    })).isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      sec: 1,
      dropdown: false
    };
  }

  componentDidMount() {
    this.timeSetter(true);
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    this.timeSetter(false);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  timeSetter = (tic) => {
    let sec = 1;
    let tac;
    if (tic) {
      tac = setInterval(() => {
        sec += 1;
        this.setState({ sec });
      }, 1000);
    } else {
      clearInterval(tac);
    }
  }

  formatCurrentTime = (timezone) => {
    const { locale, currentTime } = this.props;
    const { sec } = this.state;
    return moment(currentTime).add(sec, 's').tz(timezone).format(dateTimeFormat(TIME_FORMAT, locale));
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ dropdown: false });
  }

  selectTimezone = (timezone) => {
    this.setState({ dropdown: false });
    this.props.actions.setCurrentTimezone(timezone);
  }

  dropDownHandler = () => {
    this.setState({
      dropdown: !this.state.dropdown
    });
  }

  render() {
    const { currentTimezone, timezones } = this.props;
    const { dropdown } = this.state;

    return (
      <div ref={ node => { this.node = node } } className={ classNames('dropdown tasks-menu', { open: dropdown }) }>
        <button className="dropdown-toggle" onClick={ () => { this.dropDownHandler(); } }>
          <AdminUiIconComponent mod="clock-o" adminLte /> { currentTimezone.name }
          <span className="timezone-current-name">&nbsp; &nbsp;</span>
          { this.formatCurrentTime(currentTimezone.zone) }&nbsp;
          <span className="caret" />
        </button>
        <ul className="dropdown-menu menu">
          { dropdown &&
            timezones.map(tz => (
              <li
                className={ classNames('switch-timezone-react', { 'is-active': tz.zone === currentTimezone.zone }) }
                role="select"
                key={ Math.random() }
                value={ tz.zone }
                onClick={ () => { this.selectTimezone(tz); } }
                onKeyPress={ () => {} }
              >
                { tz.name }
                <span className="pull-right">{ this.formatCurrentTime(tz.zone) }</span>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.intl.locale,
  currentTime: state.timezones.currentTime,
  currentTimezone: state.timezones.currentTimezone,
  timezones: state.timezones.timezones
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTimezoneSelectContainer);
