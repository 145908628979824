import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import 'lib/moment/ru';
import 'lib/moment/en-gb';

import { dateTimeFormat } from 'lib/date_time';

import { DATE_TIME_SELECTOR } from 'constants/date_time';


class TimezoneObserverComponent extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currentTimezone: PropTypes.shape({
      name: PropTypes.string.isRequired,
      zone: PropTypes.string.isRequired
    }).isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      locale: this.props.locale,
      currentTimezone: this.props.currentTimezone
    };
  }

  componentWillMount() {
    this.observe();
  }

  componentWillReceiveProps({ locale, currentTimezone }) {
    this.setState({ locale, currentTimezone }, () => { this.observe(); });
  }

  observe = () => {
    const { locale, currentTimezone } = this.state;
    if (!currentTimezone || !currentTimezone.zone) return;

    const htmlCollection = document.getElementsByClassName(DATE_TIME_SELECTOR);
    const containers = Array.prototype.slice.call(htmlCollection);

    containers.forEach((el) => {
      const time = el.attributes['data-timestamp'].value.trim();
      const format = el.attributes['data-format-name'].value.trim();
      el.innerHTML = moment(time).tz(currentTimezone.zone).locale(locale).format(dateTimeFormat(format, locale));
      return el.innerHTML;
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  locale: state.intl.locale,
  currentTimezone: state.timezones.currentTimezone
});

export default connect(mapStateToProps)(TimezoneObserverComponent);
