import { createActions as actions } from 'constants/admin/user_disciplines';
import denormalizeData from 'helpers/json_api_utils/denormalizeData';

const initialState = {
  disciplines: [],
  regions: [],
  logo: '',
  defaultLogo: '',
  base64Avatar: null,
  initialValues: {
    gender: 'other',
    allow_request: true
  }
};

const adminUserDisciplinesNewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.FETCH_INITIAL_DATA_OK:
      return {
        ...state,
        defaultLogo: payload.included[0].attributes.image.thumb_270
      };
    case actions.FETCH_DISCIPLINES_OK:
      return {
        ...state,
        disciplines: denormalizeData(payload)
      };
    case actions.FETCH_REGIONS_OK:
      return {
        ...state,
        regions: payload
      };
    case actions.RESET_REGIONS:
      return {
        ...state,
        regions: []
      };
    case actions.SET_AVATAR:
      return {
        ...state,
        base64Avatar: payload
      };
    case actions.FETCH_DATA_USER_OK:
      return {
        ...state,
        logo: payload.user.logo_270
      };
    case actions.REMOVE_LOGO_LINK:
      return {
        ...state,
        logo: null
      };
    default:
      return {
        ...state
      };
  }
};

export default adminUserDisciplinesNewReducer;
