const ATTRS = 'attributes';
const RELATIONSHIPS = 'relationships';
const LINKS = 'links';
const META = 'meta';
const PAGINATION = 'pagination';
const ID = 'id';
const TYPE = 'type';

const findInIncluded = ({ type, id }, included = []) => {
  const ans = included.find((element) => {
    if (element.id === id && element.type === type) {
      return { id, type, ...element };
    }
    return null;
  }, null);
  return ans;
};

function getAllFields(data = {}, included = []) {
  let ans = {};
  Object.keys(data).map((key) => {
    if (key === ID || key === TYPE) {
      ans[key] = data[key];
    }
    if (key === ATTRS) {
      ans = { ...ans, ...data[key] };
    }
    if (key === LINKS || key === META || key === PAGINATION) {
      ans[key] = { ...data[key] };
    }
    if (key === RELATIONSHIPS) {
      const relationShipsNames = Object.keys(data.relationships);
      relationShipsNames.map(((relationShipsName) => {
        const relationshipItem = data.relationships[relationShipsName];

        const relationshipData = relationshipItem.data;
        if (!Array.isArray(relationshipData)) {
          if (relationshipData) {
            const searchFieldsParams = {
              type: relationshipData.type,
              id: relationshipData.id
            };
            ans[relationShipsName] = denormalizeSplitData({
              ...searchFieldsParams,
              ...findInIncluded(searchFieldsParams, included)
            }, included);
          } else {
            ans[relationShipsName] = null;
          }
        } if (Array.isArray(relationshipData)) {
          ans[relationShipsName] = relationshipData.map((item) => {
            const searchFieldsParams = {
              type: item.type,
              id: item.id
            };
            return denormalizeSplitData({
              ...searchFieldsParams,
              ...findInIncluded(searchFieldsParams, included)
            }, included);
          });
        }
      }));
    }
  });
  return ans;
}

export function denormalizeSplitData(data, included) {
  let ans;
  if (data) {
    if (!Array.isArray(data)) {
      ans = getAllFields(data, included);
    } else {
      ans = [];
      for (let i = 0; i < data.length; i++) {
        ans.push(getAllFields(data[i], included));
      }
    }
  }
  return ans;
}

const denormalizeData = data => data && denormalizeSplitData(data.data, data.included);

export default denormalizeData;
