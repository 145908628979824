import { createReducer } from 'redux-act';
import * as actions from 'actions/timezones';

const initialState = { timezones: [] };

export default createReducer({

  [actions.fetchTimezonesStart]: state => ({ ...state }),
  [actions.fetchTimezonesOk]: (state, { currentTime, defaultTimezone, timezones }) => ({
    ...state,
    timezones,
    currentTime,
    defaultTimezone
  }),
  [actions.fetchTimezonesFail]: state => ({ ...state }),

  [actions.addMyTimezone]: (state, { zone, name }) => ({
    ...state,
    timezones: [...[{ zone, name }], ...state.timezones]
  }),

  [actions.setCurrentTimezone]: (state, zone) => ({
    ...state,
    currentTimezone: zone
  })

}, initialState);
