function createApiRequest(fetch) {
  return async function apiRequest(url, config) {
    const resp = await fetch(url, config);
    const json = await resp.json();
    if (resp.status !== 200) {
      if (__DEV__) {
        console.error(json);
      }
      throw new Error(resp.statusText);
    }
    return json;
  };
}

export default function createHelpers({ fetch, history }) {
  return {
    history,
    fetch,
    apiRequest: createApiRequest(fetch)
  };
}
