import { createAction } from 'redux-act';
import { i18n } from 'lib/i18n';

import { setCookie } from 'lib/cookies';
import denormalizeData from 'helpers/denormalizeData';
import formatQueryStringUrl from 'helpers/formatQueryStringUrl';

import { TZ } from 'constants/date_time';


export const addMyTimezone = createAction('add my timezone', (zone, name) => ({ zone, name }));
export const setCurrentTimezone = createAction('set current timezone', (timezone) => {
  setCookie(TZ, timezone.zone, 60, window.location.hostname, true);
  return timezone;
});

// detect which timezone should be added as "My time"
export const detectMyTimezone = (storedZone, guessedZone) => async (dispatch, getState) => {
  const myTimeLabel = i18n(getState().intl.locale, 'layouts.admin.navbar.my_time');
  if (!storedZone || getState().timezones.timezones.find(tz => tz.zone === storedZone)) {
    if (guessedZone) await dispatch(addMyTimezone(guessedZone, myTimeLabel));
  } else {
    await dispatch(addMyTimezone(storedZone, myTimeLabel));
  }
};

// detect current timezone based on cookie value, moment guessed timezone or default timezone from api
export const detectTimezone = (storedZone, guessedZone) => async (dispatch, getState) => {
  const zone = storedZone || guessedZone || getState().timezones.defaultTimezone.zone;
  dispatch(setCurrentTimezone(getState().timezones.timezones.find(tz => tz.zone === zone)));
};

export const fetchTimezonesStart = createAction('fetch timezones start');
export const fetchTimezonesOk = createAction('fetch timezones ok', data => data);
export const fetchTimezonesFail = createAction('fetch timezones fail', data => data);
export const fetchTimezones = () => async (dispatch, getState, { fetch }) => {
  await dispatch(fetchTimezonesStart());
  try {
    const isAdminPage = /^adm/.test(window.location.host); // SL3-5486
    const isSignedUser = getState().sign.signed_in;

    if (isAdminPage && !isSignedUser) return;

    const { data } = await fetch('/timezones_info.json', { method: 'GET' });
    await dispatch(fetchTimezonesOk(data));
  } catch (error) {
    dispatch(fetchTimezonesFail(error));
  }
};
