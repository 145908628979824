/* eslint-disable global-require */

const routes = {
  // Keep in mind, routes are evaluated in order
  children: [
    {
      // @TODO include all app domains(can be RegEx)
      // path: /starladder.com|starladder.com/,
      // path: 'starladder.com',
      path: JSON.parse(process.env.DOMAINS.admin),
      load: () => import('./admin'),
      // issue with dynamic imports in universal router.
      // Empty children array has to be supplied while using nested dynamically loaded routes
      children: []
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import('./not-found')
    }
  ],

  async action({ next, ...rest }) {
    // Execute each child route until one of them return the result
    const route = await next();
    // Provide default values for title, description etc.
    // route.title = `${route.title || 'Untitled Page'} - SL3`; // todo replace with needed title
    // route.description = route.description || '';
    return route;
  }
};

export default routes;
