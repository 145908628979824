import { createReducer } from 'redux-act';
import * as usersListActions from 'actions/admin/users/list';
import * as users from 'constants/admin/users';

const initialState = {
  filters: {},
  isLoading: true,
  page: 1,
  error: null
};

const adminUsersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case users.FETCH_START_ADMIN_USERS_LIST:
      return {
        ...state,
        isLoading: true
      };
    case users.FETCH_OK_ADMIN_USERS_LIST:
      return {
        ...state,
        isLoading: false,
        ...payload,
        error: null
      };
    case users.FETCH_FAIL_ADMIN_USERS_LIST:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    case users.UPDATE_FILTER_ADMIN_USERS_LIST:
      return {
        ...state,
        filters: {
          ...payload
        }
      };
    case users.CHANGE_PAGE_ADMIN_USERS_LIST:
      return {
        ...state,
        page: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default adminUsersReducer;
