function extractRootDomain(domain) {
  return domain.split('.').slice(-2).join('.');
}

function setCookie(name, value, exdays, domain, root = true) {
  let cookieDomain = domain || window.location.hostname;
  cookieDomain = root ? extractRootDomain(cookieDomain) : cookieDomain;
  const date = new Date();
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const cookieName = encodeURIComponent(value);
  document.cookie = `${name}=${cookieName}; expires=${date.toUTCString()}; domain=${cookieDomain}; path=/; samesite=Lax`;
}

function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return '';
}

export {
  setCookie,
  getCookie
};
