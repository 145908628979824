import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import intl from './intl';
import timezones from './timezones';
import admin from './admin';
import sign from './core/sign';
import adminUsersList from './admin/users/list';
import adminUserNew from './admin/users/new';
import adminUserEdit from './admin/users/edit';
import adminUserDisciplines from './admin/user_disciplines/list';
import adminUserDisciplinesEdit from './admin/user_disciplines/edit';
import adminUserDisciplinesNew from './admin/user_disciplines/new';
import adminTeamsList from './admin/teams/list';
import adminTeamMemberships from './admin/teams/memberships';
import adminTeamsEdit from './admin/teams/edit';

export default combineReducers({
  intl,
  timezones,
  admin,
  sign,
  adminUsersList,
  adminUserNew,
  adminUserEdit,
  adminUserDisciplines,
  adminUserDisciplinesEdit,
  adminUserDisciplinesNew,
  adminTeamsList,
  adminTeamsEdit,
  adminTeamMemberships,
  form: formReducer
});
