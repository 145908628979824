import { editActions as actions } from 'constants/admin/user_disciplines';
import get from 'lodash/get';
import { i18n } from 'core/services/i18n';
import denormalizeData from 'helpers/json_api_utils/denormalizeData';

const initialNotifications = {
  successMessage: '',
  errorMessage: ''
};

const initialState = {
  countries: [],
  disciplines: [],
  regions: [],
  extras: {},
  team: null,
  initialValues: {},
  ...initialNotifications
};

export const normalizeInitialData = (data) => {
  const {
    id,
    discipline,
    region,
    rating,
    user,
    nick,
    provider_id,
    provider_role_id,
    game_unique_id,
    tournament_account,
    allow_request: allowRequest,
    first_name,
    last_name,
    gender,
    birthday,
    country,
    shard,
    skype,
    fb_link,
    vk_link,
    yt_link,
    tw_link,
    verification_status: verificationStatus,
    verification_errors,
    extras,
    team,
    logo,
    mobile_model,
    fake_game_activation,
    elo_card
  } = data;

  return {
    id,
    logo: logo.image.thumb_270,
    extras: {
      data: extras,
      network: {
        isLoading: false,
        error: ''
      }
    },
    team: team && {
      name: get(team, 'name', ''),
      link: get(team, 'links.self')
    } || null,
    verificationStatus,
    verificationErrors: verification_errors,
    initialValues: {
      elo_card,
      nick,
      discipline,
      region,
      user: user && {
        nick: user.nick,
        full_name: user.full_name,
        first_name: user.first_name,
        last_name: user.last_name,
        logo: user.img.thumb_48,
        edit_url: `${user.links.self}/edit`
      },
      provider_id,
      provider_role_id,
      game_unique_id,
      tournament_account,
      allow_request: allowRequest === undefined || allowRequest === null ? true : allowRequest,
      first_name,
      last_name,
      gender,
      birthday,
      country,
      shard,
      skype,
      fb_link,
      tw_link,
      vk_link,
      yt_link,
      mobile_model,
      fake_game_activation,
      rating
    }
  };
};

const setErrorMessage = errorMessage => ({ successMessage: '', errorMessage });
const setSuccessMessage = successMessage => ({ successMessage, errorMessage: '' });

const userDisciplinesEditReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.FETCH_DATA_OK:
      return {
        ...state,
        ...normalizeInitialData(denormalizeData(payload))
      };
    case actions.NULLIFY_ELO_OK:
      return {
        ...state,
        ...normalizeInitialData(denormalizeData(payload)),
        ...setSuccessMessage(i18n('admin.user_disciplines.edit.elo_nullified'))
      };
    case actions.FETCH_DATA_FAIL:
      return {
        ...state,
        ...setErrorMessage(payload)
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        user: payload,
        ...initialNotifications
      };
    case actions.FETCH_REGIONS_OK:
      return {
        ...state,
        regions: payload
      };
    case actions.SUBMIT_FORM_START:
      return {
        ...state,
        extras: {
          ...state.extras,
          error: ''
        },
        ...initialNotifications
      };
    case actions.SUBMIT_FORM_OK:
      return {
        ...state,
        ...normalizeInitialData(denormalizeData(payload)),
        ...setSuccessMessage(i18n('admin.user_disciplines.edit.form_updated')),
        extras: {
          ...state.extras,
          network: {
            ...state.extras.network,
            error: null
          }
        }
      };
    case actions.SUBMIT_FORM_FAIL:
      return {
        ...state,
        ...setErrorMessage(payload)
      };
    case actions.UPDATE_FIELD_START:
      return {
        ...state,
        extras: {
          ...state.extras,
          error: ''
        }
      };
    case actions.UPDATE_FIELD_OK:
      return {
        ...state,
        ...normalizeInitialData(denormalizeData(payload.data)),
        ...setSuccessMessage(i18n(`admin.user_disciplines.edit.${
          payload.updatedField.refresh_game_unique_id ? 'verification_started' : 'updated'
        }`))
      };
    case actions.SUBMIT_AVATAR_START:
      return {
        ...state,
        ...initialNotifications
      };
    case actions.SUBMIT_AVATAR_OK:
      return {
        ...state,
        logo: get(denormalizeData(payload), 'logo.image.thumb_270', null),
        ...setSuccessMessage(i18n('admin.user_disciplines.edit.avatar_updated'))
      };
    case actions.SUBMIT_AVATAR_FAIL:
      return {
        ...state,
        ...setErrorMessage(payload)
      };
    case actions.UPDATE_RATING_START:
      return {
        ...state,
        extras: {
          ...state.extras,
          network: {
            isLoading: true,
            error: ''
          }
        }
      };
    case actions.UPDATE_RATING_OK:
      return {
        ...state,
        extras: {
          ...payload,
          network: {
            isLoading: false,
            error: ''
          }
        }
      };
    case actions.UPDATE_RATING_FAIL:
      return {
        ...state,
        extras: {
          ...state.extras,
          network: {
            isLoading: false,
            error: payload
          }
        }
      };
    case actions.CLOSE_ALERT:
      return {
        ...state,
        ...initialNotifications
      };
    default:
      return {
        ...state
      };
  }
};

export default userDisciplinesEditReducer;
