import * as users from 'constants/admin/users';

const initialState = {
  isSubmitError: false,
  notification: null
};

export const adminUsersNewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case users.START_FETCH_DATA_ADMIN_USERS_NEW:
      return {
        ...state,
        isLoading: true
      };
    case users.OK_FETCH_DATA_ADMIN_USERS_NEW:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isErrored: false
      };
    case users.FAIL_FETCH_DATA_ADMIN_USERS_NEW:
      return {
        ...state,
        isErrored: true
      };
    case users.UPDATE_BASE64_AVATAR_ADMIN_USERS_NEW:
      return {
        ...state,
        base64Avatar: payload
      };
    case users.FAIL_SUBMIT_FORM_ADMIN_USERS_NEW:
      return {
        ...state,
        isSubmitError: true
      };
    case users.OK_SUBMIT_FORM_ADMIN_USERS_NEW:
      return {
        ...state,
        isSubmitError: false
      };
    case users.FAIL_SUBMIT_AVATAR_ADMIN_USERS_NEW:
      return {
        ...state,
        isSubmitError: true
      };
    case users.SHOW_ALERT_ADMIN_USERS_NEW:
      return {
        ...state,
        notification: payload
      };
    case users.CLOSE_ALERT_ADMIN_USERS_NEW:
      return {
        ...state,
        notification: null
      };
    default:
      return {
        ...state
      };
  }
};

export default adminUsersNewReducer;
