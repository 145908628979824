/* eslint no-unused-vars: 0 */
import { createAction } from 'redux-act';

export const setCurrentLocale = createAction('set current locale', data => data);
export const setCurrentLocaleCall = data => async (dispatch, getState, helpers) => {
  await dispatch(setCurrentLocale(data));
};

export const changeCurrentLocale = createAction('change current locale', data => (window.location = data));
export const changeCurrentLocaleCall = data => async (dispatch, getState, helpers) => {
  await dispatch(changeCurrentLocale(data));
};
