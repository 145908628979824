import { createReducer } from 'redux-act';
import * as actions from 'actions/admin';
import * as admin from 'constants/admin';

// import { ADMIN_PER_PAGE_DEFAULT } from 'constants/admin.js';

const initialState = {
  perPage: admin.ADMIN_PER_PAGE_DEFAULT
};

export default createReducer({

  [actions.setPerPage]: (state, data) => ({ ...state, perPage: data })

}, initialState);

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case admin.ADMIN_PER_PAGE:
      return ({
        ...state
      });
    default:
      return ({
        ...state
      });
  }
};
