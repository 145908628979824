import { createReducer } from 'redux-act';
import * as actions from 'actions/intl';

const initialState = {
  locale: null,
  urlForLocales: {}
};

export default createReducer({

  [actions.setCurrentLocale]: (state, data) => ({ ...state, locale: data }),
  [actions.setCurrentLocaleCall]: (state, data) => ({ ...state, locale: data }),
  [actions.changeCurrentLocale]: (state, data) => ({ ...state, locale: data }),
  [actions.changeCurrentLocaleCall]: (state, data) => ({ ...state, locale: data })


}, initialState);
