import { listActions as actions } from 'constants/admin/user_disciplines';

const initialNetwork = {
  isLoading: false,
  error: ''
};

const initialState = {
  disciplines: [],
  regions: [],
  userDisciplines: [],
  filter: {},
  page: 1,
  total: 0,
  network: {
    ...initialNetwork
  }
};

const userDisciplinesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.FETCH_DATA_START:
      return {
        ...state,
        network: {
          ...initialNetwork,
          isLoading: true
        }
      };
    case actions.FETCH_DATA_OK:
      return {
        ...state,
        userDisciplines: payload,
        network: {
          ...initialNetwork
        }
      };
    case actions.FETCH_DATA_FAIL:
      return {
        ...state,
        ...payload,
        network: {
          ...initialNetwork,
          error: payload
        }
      };
    case actions.UPDATE_FILTER:
      return {
        ...state,
        filter: payload
      };
    case actions.FETCH_REGIONS_OK:
      return {
        ...state,
        regions: payload
      };
    case actions.FETCH_DISCIPLINES_OK:
      return {
        ...state,
        disciplines: payload
      };
    case actions.RESET_REGIONS:
      return {
        ...state,
        regions: []
      };
    case actions.CHANGE_PAGE:
      return {
        ...state,
        page: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default userDisciplinesReducer;
